import React, { useState, useEffect } from 'react';
import bossesData from '../data/bosses.json';
import RoleSelector from './RoleSelector';

const Carousel = ({ onBossSelect, onDifficultySelect, onRoleSelect }) => {
    const [selectedRaid, setSelectedRaid] = useState(bossesData.raids[0]);
    const [selectedBoss, setSelectedBoss] = useState(selectedRaid.bosses[0]);
    const [difficulty, setDifficulty] = useState('heroic'); // Default to heroic difficulty
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 770);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 770);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleRaidChange = (raid) => {
        setSelectedRaid(raid);
        setSelectedBoss(raid.bosses[0]);
        onBossSelect(raid.bosses[0], difficulty);
    };

    const handleBossChange = (boss) => {
        setSelectedBoss(boss);
        onBossSelect(boss, difficulty);
    };

    const handleDifficultyChange = (difficulty) => {
        setDifficulty(difficulty);
        onDifficultySelect(difficulty);
        onBossSelect(selectedBoss, difficulty);
    };

    useEffect(() => {
        onBossSelect(selectedBoss, difficulty);
    }, [selectedBoss, difficulty, onBossSelect]);

    return (
        <div className="carousel">
            <h2>Raid Helper</h2>
            <h3>Select Raid</h3>
            <div className="raid-selection">
                {!isMobile ? (
                    bossesData.raids.map((raid, index) => (
                        <button
                            key={index}
                            onClick={() => handleRaidChange(raid)}
                            className={raid.name === selectedRaid.name ? 'active' : ''}
                        >
                            {raid.name}
                        </button>
                    ))
                ) : (
                    <select onChange={(e) => handleRaidChange(bossesData.raids[e.target.selectedIndex])}>
                        {bossesData.raids.map((raid, index) => (
                            <option key={index} value={raid.name} selected={raid.name === selectedRaid.name}>
                                {raid.name}
                            </option>
                        ))}
                    </select>
                )}
            </div>

            <h3>Select Boss</h3>
            <div className="boss-selection">
                {!isMobile ? (
                    selectedRaid.bosses.map((boss, index) => (
                        <button
                            key={index}
                            onClick={() => handleBossChange(boss)}
                            className={boss.name === selectedBoss.name ? 'active' : ''}
                        >
                            {boss.name}
                        </button>
                    ))
                ) : (
                    <select onChange={(e) => handleBossChange(selectedRaid.bosses[e.target.selectedIndex])}>
                        {selectedRaid.bosses.map((boss, index) => (
                            <option key={index} value={boss.name} selected={boss.name === selectedBoss.name}>
                                {boss.name}
                            </option>
                        ))}
                    </select>
                )}
            </div>

            <h3>Select Difficulty</h3>
            <div className="difficulty-selector">
                <button
                    className={difficulty === 'heroic' ? 'active' : ''}
                    onClick={() => handleDifficultyChange('heroic')}
                >
                    Heroic
                </button>
                <button
                    className={difficulty === 'mythic' ? 'active' : ''}
                    onClick={() => handleDifficultyChange('mythic')}
                >
                    Mythic
                </button>
            </div>

            <h3>Filter Roles</h3>
            <RoleSelector onRoleSelect={onRoleSelect} />
        </div>
    );
};

export default Carousel;
