import React, { useState, useEffect } from 'react';
import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import Carousel from './components/Carousel';
import BossDetails from './components/BossDetails';
import bossesData from './data/bosses.json';
import Recruitment from './components/Recruitment';
import Footer from './components/Footer';

const App = () => {
  const [selectedRoles, setSelectedRoles] = useState(['tank', 'heal', 'dps']); // Default to all roles
  const [selectedRaid, setSelectedRaid] = useState(bossesData.raids[0]); // Default to the first raid
  const [selectedBoss, setSelectedBoss] = useState(bossesData.raids[0].bosses[0]); // Default to the first boss
  const [difficulty, setDifficulty] = useState('heroic'); // Default to heroic difficulty

  const handleRoleSelect = (roles) => {
    setSelectedRoles(roles);
  };

  const handleRaidSelect = (raid) => {
    setSelectedRaid(raid);
    setSelectedBoss(raid.bosses[0]); // Preload the first boss of the selected raid
  };

  const handleBossSelect = (boss, difficulty) => {
    setSelectedBoss(boss);
  };

  const handleDifficultySelect = (difficulty) => {
    setDifficulty(difficulty);
  };

  useEffect(() => {
    if (selectedRaid) {
      setSelectedBoss(selectedRaid.bosses[0]);
    }
  }, [selectedRaid]);

  useEffect(() => {
    if (selectedBoss && selectedRoles.length > 0) {
      // Trigger a re-render to show abilities based on roles
      setSelectedBoss({ ...selectedBoss });
    }
  }, [selectedRoles]);

  return (
    <div className="App">
      <Header />
      <main>
      <div className="home">
        <Hero />
        <Recruitment />
      </div>
        <Carousel
          onBossSelect={handleBossSelect}
          onDifficultySelect={handleDifficultySelect}
          onRoleSelect={handleRoleSelect}
        />
        <BossDetails boss={selectedBoss} roles={selectedRoles} difficulty={difficulty} />
      </main>
      <Footer />
    </div>
  );
};

export default App;