import React from 'react';
import '../styles.css'; // Assuming you have some basic styles for your modal

const Modal = ({ isOpen, onClose, imageSrc }) => {
    if (!isOpen) return null;

    const isMp4 = imageSrc.endsWith('.mp4');

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                {isMp4 ? (
                    <video src={imageSrc} controls autoPlay style={{ maxWidth: '100%', maxHeight: '100%' }} />
                ) : (
                    <img src={imageSrc} alt="Modal content" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                )}
                <button className="modal-close-button" onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default Modal;
