import React from 'react';
import logo from '../images/Vibe_Logo_RE.webp'; // Update with the correct path to your logo
import '../styles.css'; // Import the CSS file for styling

const Footer = () => {
    return (
        <footer className="footer">
            <img src={logo} alt="Logo" className="footer-logo" />
        </footer>
    );
};

export default Footer;
