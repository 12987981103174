import React, { useState, useEffect } from 'react';
import '../styles.css'; // Ensure your styles are imported here
import axios from 'axios';

const Recruitment = () => {
  const [guildData, setGuildData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGuildData = async () => {
      try {
        const response = await axios.get(
          'https://raider.io/api/v1/guilds/profile',
          {
            params: {
              region: 'eu',
              realm: 'ravencrest',
              name: 'vibe',
              fields: 'raid_progression,raid_rankings', // Fetch progression and ranking data
            },
          }
        );

        console.log('Guild Data:', response.data); // Log the data to inspect all available fields
        setGuildData(response.data); // Save the data to state
      } catch (err) {
        setError(err.message);
        console.error('Error fetching guild data:', err);
      }
    };

    fetchGuildData();
  }, []);

  const formatRaidName = (raidName) => {
    // Capitalize and replace hyphens with spaces
    return raidName.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const renderProgressionAndRankings = (progression, ranking) => {
    if (!progression || !ranking) {
      return <p>No progression or ranking data available.</p>;
    }

    return (
      <div className="progression-rankings">
        <div className="raid-progression">
          <h3>Raid Progression</h3>
          <p><b>Normal:</b> {progression.normal_bosses_killed || 0}/{progression.total_bosses || 0} cleared</p>
          <p><b>Heroic:</b> {progression.heroic_bosses_killed || 0}/{progression.total_bosses || 0} cleared</p>
          <p><b>Mythic:</b> {progression.mythic_bosses_killed || 0}/{progression.total_bosses || 0} cleared</p>
        </div>

        <div className="raid-rankings">
          <h3>Raid Rankings</h3>
          {ranking.normal && <p><b>Normal Realm Rank:</b> {ranking.normal.realm || 'N/A'}</p>}
          {ranking.heroic && <p><b>Heroic Realm Rank:</b> {ranking.heroic.realm || 'N/A'}</p>}
          {ranking.mythic && <p><b>Mythic Realm Rank:</b> {ranking.mythic.realm || 'N/A'}</p>}
        </div>
      </div>
    );
  };

  return (
    <div className="recruitment-section">
      <div className="recruitment-content">
        <div className="recruitment-message">
          <h2>Join us on Discord</h2>
          <p>
            We're always looking for new members to join Vibe. Whether you're looking for Mythic progression raiding or simply a group of people to do dungeon content with, there's a place for you here. Click the button below to join the Guild's Discord server.
          </p>
          <div className="recruitment-button-container">
            <a
              href="https://discord.gg/GPrHQTGyAt"
              target="_blank"
              rel="noopener noreferrer"
              className="recruitment-button"
            >
              Join Now
            </a>
          </div>
        </div>
        <div className="recruitment-progress">
          {error ? (
            <div>Error fetching guild data: {error}</div>
          ) : !guildData ? (
            <div>Loading guild data...</div>
          ) : (
            <div>
              <h2>
                {guildData.name} - {guildData.realm}
              </h2>
              <h3>{formatRaidName('nerubar-palace')}</h3> {/* Display raid name here */}
              {renderProgressionAndRankings(
                guildData.raid_progression ? guildData.raid_progression['nerubar-palace'] : null,
                guildData.raid_rankings ? guildData.raid_rankings['nerubar-palace'] : null
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Recruitment;
