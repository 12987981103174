import React from 'react';
import { Link } from 'react-scroll';
import logo from '../images/Vibe_Logo_RE.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';

const Header = () => {
    return (
        <header>
            <div className="logo">
                <img src={logo} alt="Logo" />
            </div>
            <nav>
                <Link
                    to="home"
                    smooth={true}
                    duration={500}
                    offset={-92.77} // Adjusted offset for the fixed header height
                    activeClass="active"
                >
                    Home
                </Link>
                <Link
                    to="carousel"
                    smooth={true}
                    duration={500}
                    offset={-92.5} // Adjusted offset for the fixed header height
                    activeClass="active"
                >
                    Raid Helper
                </Link>
                <a
                    href="https://discord.gg/GPrHQTGyAt"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="discord-icon"
                >
                    <FontAwesomeIcon icon={faDiscord} />
                </a>
            </nav>
        </header>
    );
};

export default Header;
