import React, { useState, useEffect } from 'react';
import tankIcon from '../images/role_tank.png';
import healIcon from '../images/role_heal.png';
import dpsIcon from '../images/role_dps.png';
import Modal from './Modal';

const BossDetails = ({ boss, roles, difficulty }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImageSrc, setModalImageSrc] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (boss) {
            setIsLoading(true); // Set loading to true when boss changes
            const timeoutId = setTimeout(() => {
                setIsLoading(false); // Simulate loading complete
            }, 500); // Reduce the delay to minimize disruption

            return () => clearTimeout(timeoutId); // Cleanup timeout if component unmounts
        }
    }, [boss]);

    useEffect(() => {
        if (!isLoading && window.$WowheadPower) {
            window.$WowheadPower.refreshLinks();
        }
    }, [isLoading, boss, difficulty]);

    if (!boss) {
        return <div className="boss-details">Select a boss to see the abilities</div>;
    }

    if (isLoading) {
        return (
            <div className="boss-details">
                <div className="loading-message">
                    <div className="spinner"></div> 
                    Loading boss details...
                </div>
            </div>
        );
    }

    if (boss.work_in_progress) {
        return (
            <div className="boss-details wip">
                <div className="boss-banner">
                    <img src={boss.banner} alt={boss.name} />
                    <div className="boss-name">{boss.name}</div>
                </div>
                <div className="wip-message">
                    <h2>Work in Progress</h2>
                    <p>
                        This boss encounter is currently a work in progress. We are still gathering
                        information and strategies. Stay tuned for updates! Join our Discord to get the
                        latest news and announcements.
                    </p>
                </div>
            </div>
        );
    }

    const openModal = (mediaSrc) => {
        setModalImageSrc(mediaSrc);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalImageSrc('');
    };

    const renderDescription = (description) => {
        return description.split(/(\{tooltip:\d+\})/g).map((part, index) => {
            const match = part.match(/\{tooltip:(\d+)\}/);
            if (match) {
                const spellId = match[1];
                return (
                    <a
                        key={index}
                        href={`https://www.wowhead.com/spell=${spellId}`}
                        data-wowhead={`spell=${spellId}`}
                        className="tooltip-link"
                    >
                        {`Spell ${spellId}`}
                    </a>
                );
            }
            return <span key={index}>{part}</span>;
        });
    };

    const renderMedia = (mediaSrc) => {
        const isMp4 = mediaSrc.endsWith('.mp4');
        if (isMp4) {
            return (
                <video 
                    src={mediaSrc} 
                    controls 
                    style={{ maxHeight: '250px', cursor: 'pointer' }} 
                    onClick={() => openModal(mediaSrc)}
                />
            );
        } else {
            return (
                <img 
                    src={mediaSrc} 
                    alt="Ability media" 
                    style={{ maxHeight: '250px', cursor: 'pointer' }} 
                    onClick={() => openModal(mediaSrc)}
                />
            );
        }
    };

    return (
        <div className="boss-details">
            <div className="boss-banner">
                <img src={boss.banner} alt={boss.name} />
                <div className="boss-name">{boss.name}</div>
            </div>

            {boss.phases.map((phase, phaseIndex) => (
                <div key={phaseIndex} className="phase-section">
                    <div className="phase-header">
                        <h3>{renderDescription(phase.phaseName)}</h3>
                        <p>
                            {renderDescription(
                                difficulty === 'mythic' && phase.mythic_description
                                    ? phase.mythic_description
                                    : phase.description
                            )}
                        </p>
                    </div>

                    <div className="abilities">
                        {phase.abilities
                            .filter(ability =>
                                ability.roles.some(role => roles.includes(role)) &&
                                (ability.difficulty === difficulty || (difficulty === 'mythic' && ability.difficulty === 'heroic'))
                            )
                            .map((ability, index) => (
                                <div
                                    key={index}
                                    className={`ability ${index % 2 === 1 ? 'alternate' : ''}`}
                                >
                                    <div className="ability-gif">
                                        {renderMedia(ability.media)}
                                    </div>
                                    <div className="ability-info">
                                        {ability.difficulty === 'mythic' && <p className="mythic-only">Mythic only</p>}
                                        {ability.tank_swap && <p className="tank-swap">Taunt swap required!</p>}
                                        <h4 className="ability-title">
                                            {ability.spellId ? (
                                                <a href={`https://www.wowhead.com/spell=${ability.spellId}`} data-wowhead={`spell=${ability.spellId}`}>
                                                    {ability.name}
                                                </a>
                                            ) : (
                                                ability.name
                                            )}
                                        </h4>
                                        <p className="ability-description">{renderDescription(ability.description)}</p>
                                        {ability.mythic_description && difficulty === 'mythic' && (
                                            <>
                                                <h5>Mythic changes:</h5>
                                                <p className="mythic-description">{renderDescription(ability.mythic_description)}</p>
                                            </>
                                        )}
                                        <div className="roles">
                                            {ability.roles.includes('tank') && <img src={tankIcon} alt="Tank" />}
                                            {ability.roles.includes('heal') && <img src={healIcon} alt="Heal" />}
                                            {ability.roles.includes('dps') && <img src={dpsIcon} alt="DPS" />}
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            ))}

            <Modal
                isOpen={isModalOpen}
                onClose={closeModal}
                imageSrc={modalImageSrc}
            />
        </div>
    );
};

export default BossDetails;
