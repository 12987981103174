import React from 'react';
import '../styles.css';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

// Add the icon to the library
library.add(faDiscord);

const Hero = () => (
    <div id="hero" className="hero">
        <div className="hero-content">
            <h1>Vibe: PvE Guild</h1>
        </div>
    </div>
);

export default Hero;