// RoleSelector.js
import React, { useState, useEffect } from 'react';
import '../styles.css';
import tankIcon from '../images/role_tank.png';
import healIcon from '../images/role_heal.png';
import dpsIcon from '../images/role_dps.png';

const RoleSelector = ({ onRoleSelect }) => {
    const roles = ['tank', 'heal', 'dps'];
    const [selectedRoles, setSelectedRoles] = useState(roles);

    const toggleRole = (role) => {
        let updatedRoles;
        if (selectedRoles.includes(role)) {
            updatedRoles = selectedRoles.filter((r) => r !== role);
        } else {
            updatedRoles = [...selectedRoles, role];
        }
        setSelectedRoles(updatedRoles);
        onRoleSelect(updatedRoles);
    };

    // Initialize all roles as active on page load
    useEffect(() => {
        setSelectedRoles(roles);
    }, []);

    return (
        <div className="role-selector">
            <button
                className={`role-button ${selectedRoles.includes('tank') ? 'active' : ''} tank`}
                onClick={() => toggleRole('tank')}
            >
                <img src={tankIcon} alt="Tank" />
                TANK
            </button>
            <button
                className={`role-button ${selectedRoles.includes('heal') ? 'active' : ''} heal`}
                onClick={() => toggleRole('heal')}
            >
                <img src={healIcon} alt="Heal" />
                HEAL
            </button>
            <button
                className={`role-button ${selectedRoles.includes('dps') ? 'active' : ''} dps`}
                onClick={() => toggleRole('dps')}
            >
                <img src={dpsIcon} alt="DPS" />
                DPS
            </button>
        </div>
    );
};

export default RoleSelector;
